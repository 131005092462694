import { Slot } from '@radix-ui/react-slot'
import CashyLogo from '@/icons/cashy-logo.svg'
import { cn } from '@/utils/cn'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
  variant?: 'primary' | 'cta' | 'secondary' | 'link' | 'ghost'
  loading?: boolean
}

export function Button({
  className,
  variant = 'primary',
  asChild = false,
  children,
  loading,
  disabled,
  ...props
}: ButtonProps) {
  const Comp = asChild ? Slot : 'button'
  return (
    <Comp
      className={cn(
        'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium px-4 py-2',
        (variant === 'primary' || variant === 'cta') &&
          'bg-fill-brand-primary text-on-primary disabled:bg-fill-tertiary',
        variant === 'cta' && 'p-3 text-l font-semibold',
        variant === 'secondary' && 'bg-white border-2 border-black',
        variant === 'link' && 'border-b-2 border-b-accent p-0 rounded-none',
        variant === 'ghost' && '',
        className,
      )}
      disabled={loading || disabled}
      children={
        !loading ? (
          children
        ) : (
          <div className="animate-spin [animation-timing-function:ease]">
            <CashyLogo />
          </div>
        )
      }
      {...props}
    />
  )
}
